import React from 'react';
import ReactDOM from 'react-dom/client';
import Layout from './layout/Layout';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LayoutUIController } from './layout/context';
import { basePath } from './helper/constant';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={basePath}>
      <LayoutUIController>
        <Layout />
      </LayoutUIController>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function "/eProperty"
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
