// Blank Page
import Login from "../pages/Login/LoginPages";
import Register from "../pages/Register";
import ForgotPassword from "../pages/forgot-password/ForgotPassword"
// import ForgotPassword from "../pages/forgot-password/ForgotPassword";


// Dashboard Page
import Home from "../pages/Home";
// Master Data Page
import MasterCode from "../pages/MasterData/CodeMaster/MasterCode";

// Games Page
import TicTacToe from "../pages/Games/TicTacToe";
import { MasterUnit } from "../pages/MasterUnit/MasterUnit";
import { MasterSales } from "../pages/MasterSales/MasterSales";
import { MasterCostumer } from "../pages/MasterCustomer/MasterCustomer";
import MasterCluster from "../pages/MasterCluster/MasterCluster";
import MasterProject from "../pages/MasterData/MasterProject/MasterProject";

export const router = [
  { route: "/", component: <Home /> },
  { route: "/Login", component: <Login /> },
  { route: "/forgot-password", component: <ForgotPassword /> },

  { route: "/Register", component: <Register /> },
  { route: "/MasterData/CodeMaster", component: <MasterCode /> },
  { route: "/MasterData/MasterProject", component: <MasterProject /> },
  // Penjualan
  { route: "/Penjualan/MasterCluster", component: <MasterCluster /> },
  { route: "/Penjualan/MasterSubCluster", component: <MasterCluster /> },
  { route: "/Penjualan/MasterUnit", component: <MasterUnit /> },
  { route: "/Penjualan/MasterSales", component: <MasterSales /> },
  { route: "/Penjualan/MasterCustomer", component: <MasterCostumer /> },

  {
    collapse: true,
    label: "Games",
    route: [{ route: "/Games/TicTacToe", component: <TicTacToe /> }],
  },
  { route: "*", component: <Home /> },
];

export const mapDefault = [
  {
    url: "/Profile",
    menuActive: ["cp_profile", "profile"],
    breadcrumbs: ["Profile"],
  },
  {
    url: "/ChangePassword",
    menuActive: ["cp_profile", "change_password"],
    breadcrumbs: ["Change Password"],
  },
];

export const menuSidenav = [
  {
    menucode: "M00001",
    menuname: "Dashboard",
    menuurl: "/",
    icon: "dashboard",
  },
  {
    menucode: "M00002",
    menuname: "Master Data",
    icon: "app_registration",
    child: [
      {
        menucode: "M00003",
        menuname: "Code Master",
        menuurl: "/MasterData/CodeMaster",
      },
      {
        menucode: "M00004",
        menuname: "Master Project",
        menuurl: "/MasterData/MasterProject",
      },
    ],
  },
  {
    menucode: "M00005",
    menuname: "Penjualan",
    icon: "app_registration",
    child: [
      {
        menucode: "M00006",
        menuname: "Master Cluster",
        menuurl: "/Penjualan/MasterCluster",
      },
      {
        menucode: "M00007",
        menuname: "Master Sub CLuster",
        menuurl: "/Penjualan/MasterSubCluster",
      },
      {
        menucode: "M00008",
        menuname: "Master Unit",
        menuurl: "/Penjualan/MasterUnit",
      },
      {
        menucode: "M00009",
        menuname: "Master Sales",
        menuurl: "/Penjualan/MasterSales",
      },
      {
        menucode: "M00010",
        menuname: "Master Customer",
        menuurl: "/Penjualan/MasterCustomer",
      },
    ],
  },
  {
    menucode: "M00098",
    menuname: "Login",
    menuurl: "/Login",
    icon: "login",
  },
  {
    menucode: "M00099",
    menuname: "Register",
    menuurl: "/Register",
    icon: "how_to_reg",
  },
];
