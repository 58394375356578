import { useEffect, useState } from "react";
import React from "react";
import PropTypes from "prop-types";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { PageInit } from "../../helper/PageFunction";
import { baseApi } from "../../helper/constant";
import { message, resApi } from "../../helper/function";

import { DataTableBtn, PageCanvas } from "../../components/BaseElement/Page";
import { ModalButton, ModalCanvas } from "../../components/BaseElement/Modal";
import DataTable from "../../components/BaseElement/TableV7";
import MDButton from "../../components/MDButton";
import FormGroup from "../../components/BaseElement/FormData";
import MDBox from "../../components/MDBox";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Stack, Button, Typography, Stepper, Step, StepLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

const tableHeader = [
  { Header: "Sales ID", accessor: "salesId" },
  { Header: "Sales Name", accessor: "salesName" },
  { Header: "Category", accessor: "category" },
  { Header: "Region", accessor: "region" },
  { Header: "NPWP", accessor: "npwp" },
  { Header: "HP", accessor: "hp" },
  { Header: "Email", accessor: "email" },
];

export const MasterSales = () => {
  const { pageAccess } = PageInit();
  const [header, setHeader] = useState(tableHeader);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({ open: false, action: "Add", data: {} });

  useEffect(() => {
    let columns = tableHeader;
    if (pageAccess.edits == true || pageAccess.deletes == true) {
      columns = [
        ...columns,
        {
          Header: "Action",
          align: "center",
          sort: false,
          accessor: (row, idx) => {
            return (
              <>
                <Stack direction="row" spacing={1}>
                  <DeleteIcon
                    fontSize="medium"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                  <EditIcon
                    fontSize="medium"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                </Stack>
              </>
            );
          },
        },
      ];
    }
    setHeader([
      {
        id: "row_btn",
        Header: () => (
          <>
            <MDButton
              color="success"
              sx={{
                display: pageAccess.adds == false && "none",
                marginRight: "2px !important",
              }}
              onClick={addData}
            >
              Add
            </MDButton>
          </>
        ),
        columns: columns,
      },
    ]);
  }, [pageAccess]);

  const reload = (value = "") => {
    resApi({
      url: baseApi + "MasterData/MasterCode/ListCodeMaster",
      data: { mastercode: value },
      proccess: (result) => setData(result.data),
    });
  };

  useEffect(() => {
    reload();
  }, []);

  // function Add, Edit and Close in modal AddEditModal
  const addData = () => setModal({ open: true, action: "Add", data: {} });
  const editData = (e, data) => setModal({ open: true, action: "Edit", data: data });
  const closeModal = () => setModal({ ...modal, open: false });
  // function Show and Close in modal DetailCode

  return (
    <PageCanvas title={"Master Sales"}>
      <DataTable entriesPerPage={false} table={{ columns: header, rows: data }} />
      <AddEditModal
        open={modal.open}
        onClose={closeModal}
        action={modal.action}
        data={modal.data}
        reload={reload}
      />
    </PageCanvas>
  );
};

const tableHeaderAssign = [
  { Header: "Project Name", accessor: "projectName" },
  { Header: "Level", accessor: "level" },
  { Header: "Start Mou", accessor: "startMou" },
  { Header: "End Mou", accessor: "endMou" },
  { Header: "Commision", accessor: "commision" },
];

const AddEditModal = ({ open, onClose, action, data, reload }) => {
  const [resolver, setResolver] = useState();
  const { handleSubmit, control, setValue, clearErrors } = useForm({
    defaultValues: data,
    resolver: yupResolver(resolver),
  });
  // ASSIGN PROJECT
  const steps = ["SALES DATA", "ASSIGN PROJECT"];
  const [activeStep, setActiveStep] = React.useState(0);

  const [assignTable, setAssignTable] = useState(false);
  const [assignProject, setAssignProject] = useState();
  const { pageAccess } = PageInit();
  const [headerAssign, setHeaderAssign] = useState(tableHeaderAssign);
  const [dataAssign, setDataAssign] = useState([]);
  const [modalAssign, setModalAssign] = useState({ open: false, data: {} });

  useEffect(() => {
    let columns = tableHeaderAssign;
    if (pageAccess.edits == true || pageAccess.deletes == true) {
      columns = [
        ...columns,
        {
          Header: "Action",
          align: "center",
          sort: false,
          accessor: (row, idx) => {
            return (
              <>
                <Stack direction="row" spacing={1}>
                  <DeleteIcon
                    fontSize="medium"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                  <EditIcon
                    fontSize="medium"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                </Stack>
              </>
            );
          },
        },
      ];
    }
    setHeaderAssign([
      {
        id: "row_btn",
        Header: () => <></>,
        columns: columns,
      },
    ]);
  }, [pageAccess]);

  const reloadAssign = (value = "") => {
    resApi({
      url: baseApi + "MasterData/MasterCode/ListCodeMaster",
      data: { mastercode: value },
      proccess: (result) => setDataAssign(result.data),
    });
  };

  useEffect(() => {
    reloadAssign();
  }, []);

  const handleAssignTable = () => {
    setAssignTable(true);
    setActiveStep((currentStep) => currentStep + 1);
    // resolver: yupResolver(resolver);
    // addData(false);
  };
  const prevAssignTable = () => {
    setAssignTable(false);
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleAssignProject = () => {
    setAssignProject(true);
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#ffffff",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  useEffect(() => {
    setResolver(
      yup.object({
        masterSales: action == "Add" ? yup.string().optional() : yup.string().required(),
        // salesId: yup.string().required(),
        // salesName: yup.string().required(),
        // type: yup.string().required(),
        // npwp: yup.string().required(),
        // email: yup.string().required().email(),
        // phoneNumber: yup.string().required(),
        // whatsappNumber: yup.string().required(),
        // country: yup.string().required(),
        // province: yup.string().required(),
        // regency: yup.string().required(),
        // district: yup.string().required(),
        // village: yup.string().required(),
        // address: yup.string().required(),
      })
    );
  }, [action]);
  useEffect(() => {
    if (open === true) {
      clearErrors();
      setValue("action", action);
      setValue("mastercode", data.mastercode);
      setValue("mastername", data.mastername);
      setValue("remark", data.remark ? data.remark : "");
      setValue("flag_active", data.flag_active === undefined ? 1 : data.flag_active);
    }
  }, [open]);

  const save = (data) => {
    resApi({
      url: baseApi + "MasterData/MasterCode/SaveCodeMaster",
      data: data,
      proccess: (result) => {
        message({ message: result.message, alert: "success" });
        reload();
        onClose();
      },
    });
  };

  const addData = () => setModalAssign({ open: true, action: "Add", data: {} });
  const editData = (e, data) => setModalAssign({ open: true, action: "Edit", data: data });
  const closeModal = () => setModalAssign({ ...modalAssign, open: false });

  return (
    <>
      {/* POP UP 1 */}
      <ModalCanvas open={open} onClose={onClose} title={"Sales Data"} maxWidth={"lg"}>
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            sx={{
              mb: "0.5rem",
              width: "27rem",
              background: "#FF004D",
              height: "9vh",
              alignItems: "center",
              minWidth: "14rem",
            }}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </MDBox>
        <MDBox component="form" role="form" noValidate>
          <Grid container spacing={1} justifyContent={"center"}>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"salesId"} control={control} label="Sales ID" variant="standard" />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup
                  name={"salesName"}
                  control={control}
                  label="Sales Name"
                  variant="standard"
                />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"type"} control={control} label="Type" variant="standard" />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"npwp"} control={control} label="NPWP" variant="standard" />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"npwp"} control={control} label="NPWP" variant="standard" />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"email"} control={control} label="Email" variant="standard" />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup
                  name={"phoneNumber"}
                  control={control}
                  label="Phone Number"
                  variant="standard"
                />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup
                  name={"whatsappNumber"}
                  control={control}
                  label="WhatsApp Number"
                  variant="standard"
                />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"country"} control={control} label="Country" variant="standard" />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup
                  name={"province"}
                  control={control}
                  label="Province"
                  variant="standard"
                />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup
                  // type={"select"}
                  name={"regency"}
                  control={control}
                  label="Regency"
                  variant="standard"
                />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup
                  name={"district"}
                  control={control}
                  label="District"
                  variant="standard"
                />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"village"} control={control} label="Village" variant="standard" />
              </MDBox>
            </Grid>
            <Grid xs={6}>
              <MDBox mb={1}>
                <FormGroup name={"address"} control={control} label="Address" variant="standard" />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: "0.1rem" }}>
          <MDButton color="black" onClick={handleSubmit(handleAssignTable)}>
            {"Next"}
          </MDButton>
        </MDBox>

        {/* POP UP 2 */}
        {assignTable && (
          <ModalCanvas
            open={modalAssign}
            onClose={prevAssignTable}
            maxWidth="lg"
            title={"Assign"}
            sx={{ textAlign: "center"}}
          >
            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                sx={{
                  mb: "0.5rem",
                  width: "27rem",
                  background: "#FF004D",
                  height: "9vh",
                  alignItems: "center",
                  minWidth: "14rem",
                }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <MDBox>
              <MDButton
                sx={{
                  display: "flex",
                  background: "#FF004D",
                  color: "#ededed",
                  "&:hover": {
                    background: "#de0043",
                  },
                }}
                onClick={handleSubmit(handleAssignProject)}
              >
                {"Assign Project"}
              </MDButton>
            </MDBox>
            <DataTable entriesPerPage={false} table={{ columns: headerAssign, rows: dataAssign }} />
            <MDBox sx={{ display: "flex", justifyContent: "space-between", mt: "0.1rem" }}>
              <MDButton
                onClick={prevAssignTable}
                sx={{
                  background: "#ededed",
                  color: "#000000",
                  "&:hover": {
                    background: "#e2e2e2",
                  },
                }}
              >
                {"Prev"}
              </MDButton>
              <MDButton color="black" onClick={onClose}>
                {"Save"}
              </MDButton>
            </MDBox>
          </ModalCanvas>
        )}
      </ModalCanvas>
      {/* POP UP 3 */}
      {assignProject && (
        <ModalCanvas
          open={modalAssign}
          onClose={closeModal}
          maxWidth="lg"
          title={"Assign"}
          sx={{ textAlign: "center" }}
        >
          <MDBox>
            <MDButton>save</MDButton>
          </MDBox>
        </ModalCanvas>
      )}
    </>
  );
};
AddEditModal.defaultProps = {
  open: false,
  action: "Add",
  data: {},
};
AddEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  action: PropTypes.oneOf(["Add", "Edit"]),
  data: PropTypes.object,
  reload: PropTypes.func,
};
