import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MDButton from "../../components/MDButton";
import { PageInit } from "../../helper/PageFunction";
import { baseApi } from "../../helper/constant";
import { message, resApi } from "../../helper/function";
import { PageCanvas } from "../../components/BaseElement/Page";
import { ModalButton, ModalCanvas } from "../../components/BaseElement/Modal";
import MDBox from "../../components/MDBox";
import FormGroup from "../../components/BaseElement/FormData";
import DataTable from "../../components/BaseElement/TableV7";
import DetailCode from "../MasterData/CodeMaster/DetailCode";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack, Grid } from "@mui/material";

const tableHeader = [
    { Header: "CLUSTER ID" },
    { Header: "CLUSTER NAME" },
    { Header: "SITE PLAN" },
    { Header: "HEIGHT" },
    { Header: "WIDTH" },
];

const MasterCluster = () => {
    const { pageAccess } = PageInit();
    const [header, setHeader] = useState(tableHeader);
    const [data, setData] = useState([]);
    const [modal, setModal] = useState({ open: false, action: "Add", data: {} });
    const [viewDetail, setViewDetail] = useState({ open: false });

    useEffect(() => {
        let columns = tableHeader;
        if (pageAccess.edits === true || pageAccess.deletes === true) {
            columns = [
                ...columns,
                {
                    Header: "Action",
                    align: "center",
                    sort: false,
                    accessor: (row, idx) => (
                        <>
                            <Stack direction="row" spacing={1}>
                                <DeleteIcon
                                    fontSize="medium"
                                    sx={{
                                        display: pageAccess.deletes ? "block" : "none",
                                        padding: "1px",
                                    }}
                                    onClick={(event) => deleteData(event, row)}
                                />
                                <EditIcon
                                    fontSize="medium"
                                    sx={{
                                        display: pageAccess.edits == false && "none",
                                        padding: "1px",
                                    }}
                                    onClick={(event) => editData(event, row)}
                                />
                            </Stack>
                        </>
                    ),
                }];
        }
        setHeader([{
            id: "row_btn",
            Header: () => (
                <>
                    <MDButton
                        color="success"
                        sx={{
                            display: pageAccess.adds == false && "none",
                            marginLeft: "3px !important",
                        }}
                        onClick={addData}
                    >
                        Add
                    </MDButton>
                </>
            ),
            columns: columns,
        }]);
    }, [pageAccess]);

    const reload = (value = "") => {
        resApi({
            url: baseApi + "MasterData/MasterCode/ListCodeMaster",
            data: { mastercode: value },
            proccess: (result) => setData(result.data)
        });
    };

    useEffect(() => {
        reload();
    }, []);

    const addData = () => setModal({ open: true, action: "Add", data: {} });
    const editData = (e, data) => setModal({ open: true, action: "Edit", data: data });
    const closeModal = () => setModal({ ...modal, open: false });

    const showDetail = (e, data) => { setViewDetail({ open: true, data: data }); };
    const closeDetail = () => setViewDetail({ ...viewDetail, open: false });
    const deleteData = (e, data) => { } //Masih kosong

    return (
        <PageCanvas title={"Master Cluster"}>
            <DataTable table={{ columns: header, rows: data }} canSearch />
            <AddEditModal open={modal.open} onClose={closeModal} action={modal.action} data={modal.data} reload={reload} />
            <DetailCode open={viewDetail.open} onClose={closeDetail} parent={viewDetail.data} pageAccess={pageAccess} />
        </PageCanvas>
    );
};

const AddEditModal = ({ open, onClose, action, data, reload }) => {
    const [resolver, setResolver] = useState();
    const { handleSubmit, control, setValue, clearErrors } = useForm({
        defaultValues: data,
        resolver: yupResolver(resolver),
    });
    const [imageInfo, setImageInfo] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const image = new Image();
                image.onload = () => {
                    setSelectedImage(reader.result);
                    setImageInfo({
                        name: file.name,
                        height: image.naturalHeight,
                        width: image.naturalWidth
                    });
                    setValue("siteplan", file.name);
                    setValue("height", image.naturalHeight);
                    setValue("width", image.naturalWidth);
                };
                image.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
        setImageInfo({});
    };
    const handleSave = () => {
        handleClose();
    };


    useEffect(() => {
        setResolver(
            yup.object({
                cluster: action === "Add" ? yup.string().optional() : yup.string().required(),
                clusterid: yup.string().required(),
                clustername: yup.string().required(),
                siteplan: yup.string().required(),
                height: yup.string().required(),
                width: yup.string().required(),
                coordinate: yup.string().required(),
            })
        );
    }, [action]);

    useEffect(() => {
        if (open === true) {
            clearErrors();
            setValue("clusterid", data.clusterid);
            setValue("clustername", data.clustername);
            setValue("siteplan", data.siteplan);
            setValue("height", data.height);
            setValue("width", data.width);
            setValue("coordinate", data.coordinate);
        }
    }, [open]);

    const save = (formData) => {
        resApi({
            url: baseApi + "MasterData/MasterCode/SaveCodeMaster",
            data: formData,
            proccess: (result) => {
                message({ message: result.message, alert: "success" });
                reload();
                onClose();
            },
        });
    };

    return (
        <ModalCanvas open={open} onClose={onClose} title={action + " Master Cluster "} maxWidth="md">
            <MDBox component="form" role="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <MDBox mb={1}>
                            <FormGroup name={"clusterid"} variant="standard" control={control} label="Cluster ID" spacing={2} mb={3} />
                        </MDBox>
                        <MDBox mb={1}>
                            <FormGroup name={"clustername"} variant="standard" control={control} label="Cluster Name" spacing={2} mb={3} />
                        </MDBox>
                        <MDBox mb={1}>
                            <MDBox mb={1} style={{ position: 'relative' }}>
                                <FormGroup name={"siteplan"} variant="standard" control={control} label="Site Plan" InputProps={{
                                    readOnly: true,
                                }}/>
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="fileInput"
                                    />
                                <label htmlFor="fileInput" style={{ position: 'absolute', top: 0, right: 0 }}>
                                    <MDButton
                                        component="span"
                                        color="primary"
                                        variant="contained"
                                    >
                                        Browse
                                    </MDButton>
                                </label>
                                <MDBox mb={1}>

                                </MDBox>
                                <MDBox mb={1}>
                                    <FormGroup name={"height"} variant="standard" control={control} label="Height" InputProps={{
                                    readOnly: true,
                                }}/>
                                
                                </MDBox>
                                <MDBox mb={1}>
                                    <FormGroup name={"width"} variant="standard" control={control} label="Width" InputProps={{
                                    readOnly: true,
                                }}/>
                                    
                                </MDBox>
                                <MDBox mb={1}>
                                    <FormGroup name={"coordinate"} variant="standard" control={control} label="Coordinate" />
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </Grid>
                <Grid item xs={12} md={6}>
                    <MDBox mb={1}>
                        {selectedImage && (
                            <img src={selectedImage} alt="Selected"style={{ maxWidth: '370px', maxHeight:"237px", marginTop: "50px" }} />
                        )}
                    </MDBox>
                </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <MDButton size="small" color="primary" onClick={handleSubmit(save)}>
                            Save
                        </MDButton>
                    </Grid>
                    <Grid item>
                        <MDButton size="small" color="primary" onClick={onClose}>
                            Cancel
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
        </ModalCanvas>


    );
};

AddEditModal.defaultProps = {
    open: false,
    action: "Add",
    data: {},
};

AddEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    action: PropTypes.oneOf(["Add", "Edit"]),
    data: PropTypes.object,
    reload: PropTypes.func,
};

export default MasterCluster;
