import { Autocomplete, FormControlLabel, Switch, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const FormGroup = ({ name, control, type, ...rest }) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, formState: { errors } }) => {
                if (type === "switch") {
                    return (<FormControlLabel control={<Switch checked={field.value == 1} />} {...field}  {...rest} />);
                } else if (type === "select2") {
                    return (<Autocomplete
                        {...rest}
                        value={field.value ?? null}
                        onChange={(event, newValue) => {
                            field.onChange(newValue);
                            rest.onChange ? rest.onChange(newValue) : null;
                        }}
                        renderInput={(params) => <TextField {...params}
                            label={rest.label} required={rest.required ? true : false}
                            error={errors[field.name] ? true : false} inputRef={field.ref} />}
                    />);
                } else if (["date", "month", "year"].some(item => item === type)) {
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker fullWidth {...rest}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: rest.readOnly }}
                                value={field.value ?? ""}
                                onChange={(newValue) => field.onChange(newValue)}
                                error={errors[field.name] ? true : false}
                            />
                        </LocalizationProvider>
                    );
                } else if (type === "number") {
                    return (
                        <NumericFormat fullWidth
                            InputProps={{
                                readOnly: rest.readOnly,
                                inputProps: {
                                    style: { textAlign: "right" },
                                },
                            }}
                            allowNegative
                            thousandSeparator={true}
                            decimalScale={2}
                            {...rest}
                            customInput={TextField}
                            value={field.value ?? ""}
                            onChange={(event) => field.onChange(event)}
                            error={errors[field.name] ? true : false}
                        />
                    );
                } else {
                    return (
                        <TextField fullWidth {...rest}
                            // inputProps={{ readOnly: rest.readOnly }}
                            value={field.value ?? ""}
                            onChange={(event) => {
                                // console.log(event, field.value);
                                field.onChange(event);
                                rest.onChange ? rest.onChange(event) : null;
                            }}
                            type={type}
                            label={rest.label}
                            error={errors[field.name] ? true : false}
                        // success={field.value.length > 0 && !errors[field.name]}
                        />);
                }
            }}
        />
    );
}
FormGroup.defaultProps = {
    type: "text"
}

FormGroup.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.any.isRequired,
    type: PropTypes.oneOf(["text", "password", "switch", "select2", "date", "number", "file"]),
};

export default FormGroup;