import * as React from "react";
import {
  Avatar,
  Button,
  styled,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Stack,
  colors,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { PageLayout } from "../../helper/PageFunction";
import FormGroup from "../../components/BaseElement/FormData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PersonIcon from "@mui/icons-material/Person";

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://digitalhasanah.co.id/">
        Digital Hasanah Indonesia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const defaultTheme = createTheme();

const Register = () => {
  PageLayout();
  const onSubmit = (data) => {
    console.log(data);
    navigate("/Login");
  };
  const { handleSubmit, control, formState } = useForm({
    defaultValues: { regisname: "", password: "", picname: "", email: "", phone: "" },
    resolver: yupResolver(
      yup.object({
        regisname: yup.string().required(),
        password: yup.string().required().min(8).max(16),
        email: yup.string().email().required(),
        phone: yup.string().required(),
      })
    ),
  });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#060d0d"),
    backgroundColor: "#060d0d",
    "&:hover": {
      backgroundColor: "#122828",
    },
  }));

  const [showPassword, setShowPassword] = React.useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://image.archify.com/blog/ct9u9xn4.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#060d0d" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Register
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                name="regisname"
                label="Register Name"
                placeholder="John Doe"
                autoComplete="name"
                inputProps={{
                  startAdornment: (
                    <PersonIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                  ),
                }}
              />

              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                label="Email Address"
                placeholder="you@example.com"
                name="email"
                autoComplete="email"
                autoFocus
                inputProps={{
                  startAdornment: (
                    <EmailIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                  ),
                }}
              />
              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password (8-16 characters)"
                placeholder="Create a strong password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                inputProps={{
                  maxLength: 16,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                name="phone"
                label="Phone Number"
                placeholder="ex :123-456-7890"
                inputProps={{
                  startAdornment: (
                    <PhoneAndroidIcon
                      sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 0 }}
                    />
                  ),
                }}
              />
              <ColorButton
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                sx={{ mt: 3, mb: 2 }}
              >
                Register Now
              </ColorButton>
              <Routes>
                <Route path="/Login" element={<login />} />
              </Routes>
              <Grid item>
                <Link href="/Login" variant="body2">
                  {"Already have an account?"}
                </Link>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Register;
