import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageInit } from "../../helper/PageFunction";

import { baseApi } from "../../helper/constant";
import { message, resApi } from "../../helper/function";

import { DataTableBtn, PageCanvas } from "../../components/BaseElement/Page";
import { ModalButton, ModalCanvas } from "../../components/BaseElement/Modal";
import DataTable from "../../components/BaseElement/TableV7";
import MDButton from "../../components/MDButton";
import FormGroup from "../../components/BaseElement/FormData";
import MDBox from "../../components/MDBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Stack, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import MDTypography from "../../components/MDTypography";

// TABLE MASTER UNIT START

const tableHeader = [
  { Header: "BLOK", accessor: "blok" },
  {
    Header: "NUMBER",
    accessor: "number",
    align: "center",
  },
  { Header: "UNITID", accessor: "unitid" },
  { Header: "CATEGORY", accessor: "category" },
  { Header: "TYPE", accessor: "type" },
  { Header: "LAND M2", accessor: "landm2" },
  { Header: "BUILD M2", accessor: "buildm2" },
  { Header: "PRICE", accessor: "price" },
  { Header: "READY STOCK", accessor: "stock" },
  { Header: "KIR", accessor: "kir" },
  {
    Header: "Status",
    // accessor: (row, idx) => {
    //   return row.flag_active == "1" ? "Active" : "Non Active";
    // },
  },
];

export const MasterUnit = () => {
  const { pageAccess } = PageInit();
  const [header, setHeader] = useState(tableHeader);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({ open: false, action: "Add", data: {} });

  useEffect(() => {
    let columns = tableHeader;
    if (pageAccess.edits == true || pageAccess.deletes == true) {
      columns = [
        ...columns,
        {
          Header: "Action",
          align: "center",
          sort: false,
          accessor: (row, idx) => {
            return (
              <>
                <Stack direction="row" spacing={1}>
                  <DeleteIcon
                    fontSize="medium"
                    sx={{
                      padding: "1px",
                      cursor: "pointer",
                    }}
                  />
                  <EditIcon
                    fontSize="medium"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                      cursor: "pointer",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                </Stack>
              </>
            );
          },
        },
      ];
    }
    setHeader([
      {
        id: "row_btn",
        Header: () => (
          <>
            <MDButton
              color="success"
              sx={{
                display: pageAccess.adds == false && "none",
                marginRight: "2px !important",
              }}
              onClick={addData}
            >
              Add
            </MDButton>
            {/* <DataTableBtn
              icon={"refresh"}
              color={"success"}
              sx={{
                marginRight: pageAccess.downloads == true && "2px !important",
              }}
              onClick={() => reload()}
            /> */}
          </>
        ),
        columns: columns,
      },
    ]);
  }, [pageAccess]);

  const reload = (value = "") => {
    resApi({
      url: baseApi + "MasterData/MasterCode/ListCodeMaster",
      data: { mastercode: value },
      proccess: (result) => setData(result.data),
    });
  };

  useEffect(() => {
    reload();
  }, []);

  // function Add, Edit and Close in modal AddEditModal
  const addData = () => setModal({ open: true, action: "Add", data: {} });
  const editData = (e, data) => setModal({ open: true, action: "Edit", data: data });
  const closeModal = () => setModal({ ...modal, open: false });
  // function Show and Close in modal DetailCode

  return (
    <PageCanvas title={"Master Unit"}>
      <DataTable canSearch table={{ columns: header, rows: data }} />
      <AddEditModal
        open={modal.open}
        onClose={closeModal}
        action={modal.action}
        data={modal.data}
        reload={reload}
      />
    </PageCanvas>
  );
};

const AddEditModal = ({ open, onClose, action, data, reload }) => {
  const [resolver, setResolver] = useState();
  const { handleSubmit, control, setValue, clearErrors } = useForm({
    defaultValues: data,
    resolver: yupResolver(resolver),
  });
  useEffect(() => {
    setResolver(
      yup.object({
        projectname: action == "Add" ? yup.string().optional() : yup.string().required(),
        mastername: yup.string().required(),
        remark: yup.string(),
        flag_active: yup.bool(),
      })
    );
  }, [action]);
  useEffect(() => {
    if (open === true) {
      clearErrors();
      setValue("action", action);
      setValue("projectname", data.projectname);
      setValue("mastername", data.mastername);
      setValue("remark", data.remark ? data.remark : "");
      setValue("flag_active", data.flag_active === undefined ? 1 : data.flag_active);
    }
  }, [open]);

  const save = (data) => {
    resApi({
      url: baseApi + "MasterData/MasterCode/SaveCodeMaster",
      data: data,
      proccess: (result) => {
        message({ message: result.message, alert: "success" });
        reload();
        onClose();
      },
    });
  };

  // TABLE MASTERUNIT END

  return (
    <ModalCanvas
      open={open}
      onClose={onClose}
      maxWidth={"lg"}
      title={
        <MDBox
          sx={{
            display: "flex",
            backgroundColor: "#FF004D",
            textAlign: "center",
            maxWidth: "11rem",
            height: "3rem",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography sx={{ color: "#ffffff", fontWeight: "700" }}>MASTER UNIT</MDTypography>
        </MDBox>
      }
    >
      <MDBox component="form" role="form" noValidate>
        <Grid container spacing={2} columns={18}>
          <Grid xs={8}>
            <MDBox mb={1}>
              <FormGroup
                variant={"standard"}
                name={"projectname"}
                control={control}
                label="Project Name"
                required
              />
            </MDBox>
          </Grid>
          <Grid xs={5}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"clustername"}
                control={control}
                label="Cluster Name"
              />
            </MDBox>
          </Grid>
          <Grid xs={5}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"subclustername"}
                control={control}
                label="Sub Cluster Name"
              />
            </MDBox>
          </Grid>
          <Grid xs={6}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"category"} control={control} label="Category" />
            </MDBox>
          </Grid>
          <Grid xs={6}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"type"} control={control} label="Type" />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"blok"} control={control} label="Blok" />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"number"} control={control} label="Number" />
            </MDBox>
          </Grid>
          <Grid xs={2}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"landm2"} control={control} label="Land M2" />
            </MDBox>
          </Grid>
          <Grid xs={4}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"landpricem2"}
                control={control}
                label="Land Price /M2"
              />
            </MDBox>
          </Grid>
          <Grid xs={2}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"buildm2"} control={control} label="Build M2" />
            </MDBox>
          </Grid>
          <Grid xs={4}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"buildpricem2"}
                control={control}
                label="Build Price /M2"
              />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"unitid"} control={control} label="Unit ID" />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"stock"} control={control} label="Stock" />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"biayasurat"}
                control={control}
                label="Biaya Surat"
              />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"npoptkp"} control={control} label="NPOPTKP" />
            </MDBox>
          </Grid>
          <Grid xs={2}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"bphtb"} control={control} label="BPHTB (%)" />
            </MDBox>
          </Grid>
          <Grid xs={4}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"socialfacilities"}
                control={control}
                label="Social Facilities"
              />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"publicfacilities"}
                control={control}
                label="Public Facilities"
              />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup variant="standard" name={"view"} control={control} label="View" />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"salestype"}
                control={control}
                label="Sales Type"
              />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"unitstatus"}
                control={control}
                label="Unit Status"
              />
            </MDBox>
          </Grid>
          <Grid xs={6}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"propertyid"}
                control={control}
                label="Property ID (KIR)"
              />
            </MDBox>
          </Grid>
          <Grid xs={3}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"bookingfee"}
                control={control}
                label="Booking Fee"
              />
            </MDBox>
          </Grid>
          <Grid xs={18}>
            <MDBox mb={1}>
              <FormGroup
                variant="standard"
                name={"coordinate"}
                control={control}
                label="Coordinate"
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox sx={{ display: "flex", justifyContent: "flex-end" }} mt={2}>
          <MDButton color="black" onClick={handleSubmit(save)}>
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalCanvas>
  );
};
AddEditModal.defaultProps = {
  open: false,
  action: "Add",
  data: {},
};
AddEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  action: PropTypes.oneOf(["Add", "Edit"]),
  data: PropTypes.object,
  reload: PropTypes.func,
};
