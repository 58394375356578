/* eslint-disable no-unused-vars */
import { useState } from "react";
import { PageLayout } from "../../helper/PageFunction";
import { Button, ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme();

function Square({ value }) {
    return (<Button variant="outlined" sx={{ minWidth: "40px", minHeight: "40px" }}>{value}</Button>);
}
const Board = ({ squares }) => {
    return (<>{squares.map((value, index) => <Square key={index} value={value} />)}</>)
}

function TicTacToe() {
    PageLayout("Blank");
    const [history, setHistory] = useState([Array(9).fill(null)]);
    const [currentMove, setCurrentMove] = useState(0);
    const currentSquares = history[currentMove];

    return (<ThemeProvider theme={theme}>
        <Board squares={currentSquares} />
    </ThemeProvider>);
}


// function Square({ value, onSquareClick }) {
//     return (<>{value}</>);
// }

// function Board({ xIsNext, squares, onPlay }) {
//     const handleClick = (i) => {
//         if (squares[i] || calculateWinner(squares)) return;
//         const nextSquares = squares.slice();
//         nextSquares[i] = (xIsNext) ? "X" : "O";
//         onPlay(nextSquares);
//     }

//     const calculateWinner = (data) => {
//         const lines = [
//             [0, 1, 2],
//             [3, 4, 5],
//             [6, 7, 8],
//             [0, 3, 6],
//             [1, 4, 7],
//             [2, 5, 8],
//             [0, 4, 8],
//             [2, 4, 6],
//         ];
//         for (let i = 0; i < lines.length; i++) {
//             const [a, b, c] = lines[i];
//             if (data[a] && data[a] === data[b] && data[a] === data[c]) {
//                 return data[a];
//             }
//         }
//         return false;
//     }
//     return (<>{squares.map((value, index) => {
//         return <Square key={index} value={value} />;
//     })}</>);
// }

// function TicTacToe() {
//     PageInit("Blank");
//     const [history, setHistory] = useState([Array(9).fill(null)]);
//     const [currentMove, setCurrentMove] = useState(0);
//     const xIsNext = currentMove % 2 === 0;
//     const currentSquares = history[currentMove];

//     const handlePlay = (nextSquares) => {
//         const nextHistory = [...history.slice(0, currentMove + 1), nextSquares]
//         setHistory(nextHistory);
//         setCurrentMove(nextHistory.length - 1);
//     }

//     const jumpTo = (nextMove) => {
//         setCurrentMove(nextMove);
//     }

//     return (<>
//         Tic Tac Toe
//         <Board xIsNext={xIsNext} squares={currentSquares} onPlay={handlePlay} />
//     </>);
// }



export default TicTacToe;