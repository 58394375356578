import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageInit } from "../../../helper/PageFunction";
import { baseApi } from "../../../helper/constant";
import { message, resApi } from "../../../helper/function";

import { DataTableBtn, PageCanvas } from "../../../components/BaseElement/Page";
import { ModalButton, ModalCanvas } from "../../../components/BaseElement/Modal";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import FormGroup from "../../../components/BaseElement/FormData";
import DataTable from "../../../components/BaseElement/TableV7";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const tableHeader = [
  {
    Header: "No",
    accessor: (row, idx) => {
      return idx + 1;
    },
    align: "center",
    width: "3rem",
  },
  { Header: "Project", accessor: "projectid" },
  { Header: "Company", accessor: "companyid" },
  { Header: "Address", accessor: "addressid" },
  {
    Header: "Status",
    accessor: (row, idx) => {
      return row.flag_active == "1" ? "Active" : "Non Active";
    },
    align: "center",
    width: "5rem",
  },
];

const MasterProject = () => {
  const { pageAccess } = PageInit();
  const [header, setHeader] = useState(tableHeader);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({ open: false, action: "Add", data: {} });

  useEffect(() => {
    let columns = tableHeader;
    if (pageAccess.edits == true || pageAccess.deletes == true) {
      columns = [
        ...columns,
        {
          Header: "Action",
          align: "center",
          sort: false,
          width: "1rem",
          accessor: (row, idx) => {
            return (
              <>
                <Stack direction="row" spacing={1}>
                  <DeleteIcon
                    fontSize="medium"
                    sx={{
                      padding: "1px",
                      cursor: "pointer",
                    }}
                  />
                  <EditIcon
                    fontSize="medium"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                      cursor: "pointer",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                </Stack>
              </>
            );
          },
        },
      ];
    }
    setHeader([
      {
        id: "row_btn",
        Header: () => (
          <>
            <MDButton
              color="success"
              sx={{
                display: pageAccess.adds == false && "none",
                marginRight: "2px !important",
              }}
              onClick={addData}
            >
              Add
            </MDButton>
          </>
        ),
        columns: columns,
      },
    ]);
  }, [pageAccess]);

  const reload = (value = "") => {
    resApi({
      url: baseApi + "MasterData/MasterCode/ListCodeMaster",
      data: { mastercode: value },
      proccess: (result) => setData(result.data),
    });
  };

  useEffect(() => {
    reload();
  }, []);
  const addData = () => setModal({ open: true, action: "Add", data: {} });
  const editData = (e, data) => setModal({ open: true, action: "Edit", data: data });
  const closeModal = () => setModal({ ...modal, open: false });
  return (
    <PageCanvas title={"Master Project"}>
      <DataTable table={{ columns: header, rows: data }} canSearch />
      <AddEditModal
        open={modal.open}
        onClose={closeModal}
        action={modal.action}
        data={modal.data}
        reload={reload}
      />
    </PageCanvas>
  );
};

const AddEditModal = ({ open, onClose, action, data, reload }) => {
  const [resolver, setResolver] = useState();
  const { handleSubmit, control, setValue, clearErrors } = useForm({
    defaultValues: data,
    resolver: yupResolver(resolver),
  });
  useEffect(() => {
    setResolver(
      yup.object({
        masterproject: action == "Add" ? yup.string().optional() : yup.string().required(),
        project: yup.string().required(),
        company: yup.string().required(),
        // address:yup.string().required(),
      })
    );
  }, [action]);
  useEffect(() => {
    if (open === true) {
      clearErrors();
      setValue("action", action);
      setValue("mastercode", data.mastercode);
      setValue("mastername", data.mastername);
      setValue("remark", data.remark ? data.remark : "");
      setValue("flag_active", data.flag_active === undefined ? 1 : data.flag_active);
    }
  }, [open]);

  const save = (data) => {
    resApi({
      url: baseApi + "MasterData/MasterCode/SaveCodeMaster",
      data: data,
      proccess: (result) => {
        message({ message: result.message, alert: "success" });
        reload();
        onClose();
      },
    });
  };
  return (
    <ModalCanvas open={open} onClose={onClose} title={action + " Data Code Master"} maxWidth={"md"}>
      <MDBox component="form" role="form" noValidate>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <MDBox mb={1}>
              <FormGroup name={"project"} control={control} label="Project" required />
            </MDBox>
          </Grid>
          <Grid xs={6}>
            <MDBox mb={1}>
              <FormGroup name={"company"} control={control} label="Company" required />
            </MDBox>
          </Grid>
          <Grid xs={12}>
            <MDBox mb={1}>
              <FormGroup name={"address"} control={control} label="Address" multiline rows={4} />
            </MDBox>
          </Grid>
          <MDBox mb={1}>
            <FormGroup name={"flag_active"} control={control} label="Status" type={"switch"} />
          </MDBox>
        </Grid>
        <ModalButton action={action} onClose={onClose} onClick={handleSubmit(save)} />
      </MDBox>
    </ModalCanvas>
  );
};
AddEditModal.defaultProps = {
  open: false,
  action: "Add",
  data: {},
};
AddEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  action: PropTypes.oneOf(["Add", "Edit"]),
  data: PropTypes.object,
  reload: PropTypes.func,
};

export default MasterProject;
