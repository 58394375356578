import Axios from "axios";
import { alertMessage, loadScreen } from "./constant";

export const findMap = (dataMap = [], url = "", type = "breadcrums") => {
    let data = dataMap.find(obj => {
        return obj.url === url;
    });
    if (type === "active") {
        data = data?.menuActive;
    } else {
        data = data?.breadcrumbs;
    }
    return data ?? [];
}

// Rest API use Axios
export const resApi = async ({
    method = "POST",
    url,
    headers = {
        "Content-Type": "application/json",
        // "Authorization": cookies.get("token") ? "Bearer " + cookies.get("token") : "YOUR TOKEN",
        "AppVersion": "v1.1.0"
    },
    data,
    proccess = (result) => { },
}) => {
    loader();
    await Axios({ method, url, headers, data }).then((response) => {
        let result = response.data;
        if (result.status) {
            proccess(result);
        } else {
            message({ message: result.message, alert: "warning" });
        }
    }).catch((error) => {
        let response = error.response;
        if (response) {
            let result = response.data;
            if (response.status == 401) {

            } else if (response.status == 440) {

            } else if (response.status == 400) {
                message({ message: result.message, alert: "warning" });
            } else {
                message({ message: result.message, alert: "error" });
            }
        } else {
            message({ message: error, alert: "error" });
        }

    }).finally(f => loader(false));
}

// Message Notice use Snackbar
export const message = ({ title = "Information", message = "", alert = "info", open = true }) => {
    alertMessage.setGlobalState("open", open);
    alertMessage.setGlobalState("title", title);
    alertMessage.setGlobalState("content", message);
    alertMessage.setGlobalState("color", alert);
}

// Loading Screen
export const loader = (value = true) => loadScreen.setGlobalState("open", value);
