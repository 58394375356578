import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { setMiniSidenav, setTransparentNavbar, useLayoutUIController } from "../../context";

import { AppBar, Icon, IconButton, Toolbar } from "@mui/material";
import { navbar, navbarContainer, navbarDesktopMenu, navbarMobileMenu, navbarRow } from "./styles";
import MDBox from "../../../components/MDBox";
import Breadcrumbs from "./Breadcrumbs";

import { findMap } from "../../../helper/function";
import { mapDefault } from "../../../routes/router";

const AppNav = ({ absolute, light, isMini }) => {
    const [navbarType, setNavbarType] = useState("sticky");
    const [controller, dispatch] = useLayoutUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, darkMode, dataMap } = controller;
    const { pathname } = useLocation();
    const route = findMap(dataMap, pathname, "breadcrums");
    const title = route.length <= 0 ? "" : route[route.length - 1];

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar && navbarType !== "sticky") {
            setNavbarType("sticky");
        } else if (!fixedNavbar && navbarType !== "static") {
            setNavbarType("static");
        }
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }
        window.addEventListener("scroll", handleTransparentNavbar);

        if (transparentNavbar !== ((fixedNavbar && window.scrollY === 0) || !fixedNavbar)) {
            handleTransparentNavbar();
        }

        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    // Styles for the navbar icons
    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;
            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }
            return colorValue;
        },
    });

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <Breadcrumbs icon="home" title={title} route={route} light={light} />
                    <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
                        <Icon fontSize="medium" sx={iconsStyle}>
                            {miniSidenav ? "menu_open" : "menu"}
                        </Icon>
                    </IconButton>
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <MDBox pr={1} />
                        <MDBox color={light ? "white" : "inherit"}>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon sx={iconsStyle} fontSize="medium">
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}
AppNav.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};
AppNav.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default AppNav;