import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageInit } from "../../helper/PageFunction";
import { baseApi } from "../../helper/constant";
import { message, resApi } from "../../helper/function";
import { DataTableBtn, PageCanvas } from "../../components/BaseElement/Page";
import { ModalButton, ModalCanvas } from "../../components/BaseElement/Modal";
import MDBox from "../../components/MDBox";
import FormGroup from "../../components/BaseElement/FormData";
import DataTable from "../../components/BaseElement/TableV7";
import DetailCode from "../MasterData/CodeMaster/DetailCode";
import MDButton from "../../components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessSharpIcon from "@mui/icons-material/BusinessSharp";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { WhatsApp } from "@mui/icons-material";

const tableHeader = [
  { Header: "CUSTOMER ID", accessor: "", align: "center" },
  { Header: "CUSTOMER NAME", accessor: "", align: "center" },
  { Header: "REGENCY", accessor: "", align: "center" },
  { Header: "KTP ID", accessor: "", align: "center" },
  { Header: "NPWP", accessor: "", align: "center" },
  { Header: "AKTA", accessor: "", align: "center" },
  { Header: "EMAIL", accessor: "", align: "center" },
];

export const MasterCostumer = () => {
  const { pageAccess } = PageInit();
  const [header, setHeader] = useState(tableHeader);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({ open: false, action: "Add", data: {} });
  const [viewDetail, setViewDetail] = useState({ open: false });
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    console.log("Search triggered with term:", searchTerm);
  };

  useEffect(() => {
    let columns = tableHeader;
    if (pageAccess.edits == true || pageAccess.deletes == true) {
      columns = [
        ...columns,
        {
          Header: "Action",
          align: "center",
          sort: false,
          accessor: (row, idx) => {
            return (
              <>
                <Stack direction="row" spacing={1}>
                  <DeleteIcon
                    fontSize="small"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                  <EditIcon
                    fontSize="medium"
                    sx={{
                      display: pageAccess.edits == false && "none",
                      padding: "1px",
                    }}
                    onClick={(event) => editData(event, row)}
                  />
                </Stack>
              </>
            );
          },
        },
      ];
    }
    setHeader([
      {
        id: "row_btn",
        Header: () => (
          <>
            <div style={{ display: "flex", justifyContent: "right", marginTop: "16px" }}>
              <TextField
                label="search here"
                variant="outlined"
                size="small"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton onClick={handleSearch} aria-label="cari">
                <SearchIcon />
              </IconButton>
            </div>
            <MDButton
              color="success"
              sx={{
                display: pageAccess.adds === false ? "none" : "inline-block",
                marginRight: "3px !important",
              }}
              onClick={addData}
            >
              Add
            </MDButton>
          </>
        ),
        columns: columns,
      },
    ]);
  }, [pageAccess]);

  const reload = (value = "") => {
    resApi({
      url: baseApi + "MasterData/MasterCode/ListCodeMaster",
      data: { mastercode: value },
      proccess: (result) => setData(result.data),
    });
  };

  useEffect(() => {
    reload();
  }, []);

  // function Add, Edit and Close in modal AddEditModal

  const addData = () => setModal({ open: true, action: "", data: {} });
  const editData = (e, data) => setModal({ open: true, action: "Edit", data: data });
  const closeModal = () => setModal({ ...modal, open: false });

  // function Show, View and Close in modal DetailCode
  const showDetail = (e, data) => {
    setViewDetail({ open: true, data: data });
  };
  const closeDetail = () => setViewDetail({ ...viewDetail, open: false });

  return (
    <PageCanvas title={"Master Customer"}>
      <DataTable entriesPerPage={false} table={{ columns: header, rows: data }} />
      <AddEditModal
        open={modal.open}
        onClose={closeModal}
        action={modal.action}
        data={modal.data}
        reload={reload}
      />
      <DetailCode
        open={viewDetail.open}
        onClose={closeDetail}
        parent={viewDetail.data}
        pageAccess={pageAccess}
      />
    </PageCanvas>
  );
};

const AddEditModal = ({ open, onClose, action, data, reload }) => {
  const [resolver, setResolver] = useState();
  const { handleSubmit, control, setValue, clearErrors } = useForm({
    defaultValues: data,
    resolver: yupResolver(resolver),
  });

  const [showPersonalPopup, setShowPersonalPopup] = useState(false);
  const [showCompaniesPopup, setShowCompaniesPopup] = useState(false);

  const handlePersonalPopup = () => {
    setShowPersonalPopup(true);
  };

  const handleCompaniesPopup = () => {
    setShowCompaniesPopup(true);
  };

  const closePersonalPopup = () => {
    setShowPersonalPopup(false);
  };

  const closeCompaniesPopup = () => {
    setShowCompaniesPopup(false);
  };

  const handleClick = () => {
    message("testing");
  };
  

  useEffect(() => {
    setResolver(
      yup.object({
        costumerid: action === "Add" ? yup.string().optional() : yup.string().required(),
        costumercode: yup.string().required(),
        placeofbirth: yup.string().required(),
        gender: yup.string().required(),
        maritalstatus: yup.string().required(),
        phone: yup.string().required(),
        WhatsApp: yup.string().required(),
        email: yup.string().required(),
        //
        customername: yup.string().required(),
        dateofbirth: yup.string().required(),
        religion: yup.string().required(),
        occupations: yup.string().required(),
        citizenstatus: yup.string().required(),
        rangesalary: yup.string().required(),
        infofrom: yup.string().required(),
        //
        ID: yup.string().required(),
        statename: yup.string().required(),
        areaname: yup.string().required(),
        address: yup.string().required(),
        fotoktp: yup.string().required(),
        cityname: yup.string().required(),
        subareaname: yup.string().required(),
        postalcode: yup.string().required(),
      })
    );
  }, [action]);

  useEffect(() => {
    if (open === true) {
      clearErrors();
      setValue("costumerid", data.costumerid);
      setValue("costumername", data.costumername);
      setValue("mastername", data.mastername);
      setValue("remark", data.remark ? data.remark : "");
      setValue("flag_active", data.flag_active === undefined ? 1 : data.flag_active);
    }
  }, [open]);

  return (
    <ModalCanvas
      open={open}
      onClose={onClose}
      title={action + " Choose Your Customer"}
      maxWidth="sm"
      sx={{ textAlign: "center" }}
    >
      <Box
        mb={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 5,
            width: 150,
            height: 130,
          },
        }}
      >
        <Paper
          elevation={5}
          sx={{
            margin: "50px",
            marginBottom: "100px",
            border: "2px solid grey",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handlePersonalPopup}
        >
          {/* <PersonIcon fontSize="large" /> */}
          <img
            src="https://cdn-icons-png.freepik.com/256/1184/1184377.png?uid=R112365004&ga=GA1.1.973024858.1700729870&semt=ais"
            alt=""
            style={{ width: "100px", height: "90px" }}
          />
        </Paper>
        <Paper
          elevation={5}
          sx={{
            margin: "50px",
            marginBottom: "100px",
            border: "2px solid grey",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleCompaniesPopup}
        >
          <img
            src="https://cdn-icons-png.freepik.com/256/24/24914.png?uid=R112365004&ga=GA1.1.973024858.1700729870&semt=ais"
            alt=""
            style={{ width: "100px", height: "80px" }}
          />
        </Paper>
      </Box>

      <MDBox
        mt={2}
        display="flex"
        flexDirection="row"
        // textAlign="center"
      >
        <Typography marginLeft={13} mt={-11} variant="h5" mr={4}>
          Personal
        </Typography>
      </MDBox>
      <MDBox marginLeft="347px" display="flex" flexDirection="row">
        <Typography mt={-11} variant="h5">
          Companies
        </Typography>
      </MDBox>

      {/* Popup untuk Personal */}
      {showPersonalPopup && (
        <ModalCanvas
          open={true}
          onClose={closePersonalPopup}
          title={action + "Master Customer (New) - Individual"}
          maxWidth="xl"
          sx={{ textAlign: "left" }} 
        >
          {/* disini */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: -1,
            }}
          >
            {/* <Typography variant="h6">Master Customer (New) - Individual</Typography> */}
          </Box>
          <Grid container spacing={3}>
            {/* Grid di sebelah kiri */}
            <Grid xs={6}>
              <TextField label="Customer Code" fullWidth sx={{ marginBottom: 2 }} />
              <TextField label="Place of Birth" fullWidth sx={{ marginBottom: 2 }} />
              <TextField
                label="Gender"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon fontSize="medium" />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Marital Status"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon fontSize="medium"></KeyboardArrowDownSharpIcon>
                    </IconButton>
                  ),
                }}
              />

              {/* Grid untuk nomor telepon */}
              <Grid container spacing={1}>
                <Grid fullWidth xs={9}>
                  <TextField label="Phone" fullWidth sx={{ marginBottom: 2, marginRight: 17 }} />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    label="+62 - Indonesia"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <KeyboardArrowDownSharpIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {/* Grid untuk WhatsApp */}
              <Grid container spacing={1}>
                <Grid fullWidth xs={9}>
                  <TextField label="WhatsApp" fullWidth sx={{ marginBottom: 2, marginRight: 17 }} />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    label="+62 - Indonesia"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <KeyboardArrowDownSharpIcon></KeyboardArrowDownSharpIcon>
                        </IconButton>
                      ),
                    }}
                  />
                  <Grid xs={3}></Grid>
                </Grid>
                <Grid xs={3}></Grid>
              </Grid>

              {/* Grid untuk email */}
              <TextField label="Email" fullWidth />
            </Grid>

            {/* Grid di sebelah kanan */}
            <Grid xs={6}>
              <TextField label="Customer Name" fullWidth sx={{ marginBottom: 2 }} />
              <Grid container spacing={1}>
                <Grid xs={6} sx={{ marginBottom: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Date of Birth" fullWidth />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <TextField
                label="Religion"
                fullWidth
                sx={{ marginBottom: 2, marginTop: 1 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon></KeyboardArrowDownSharpIcon>
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Occupations"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon></KeyboardArrowDownSharpIcon>
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Citizen Status"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon></KeyboardArrowDownSharpIcon>
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Range Salary"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon></KeyboardArrowDownSharpIcon>
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Info From"
                fullWidth
                sx={{ marginTop: 0.5 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon></KeyboardArrowDownSharpIcon>
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {/* Tabel kedua ............................ */}

          {/* Grid data */}
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Paper
                elevation={3}
                variant="outlined"
                sx={{ padding: 1, borderColor: "black", borderWidth: "12px" }}
              >
                {/* Penambahan tombol-tombol di sini */}
                <Grid container justifyContent="flex-start" spacing={2} sx={{ marginTop: -3 }}>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      KTP
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      NPWP
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      Correspondence
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      Domisili
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  {/* Grid di sebelah kiri */}
                  <Grid xs={6}>
                    <TextField label="ID" fullWidth sx={{ marginBottom: 2 }} />
                    <TextField
                      label="State Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField
                      label="Area Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField
                      label="Address"
                      fullWidth
                      multiline // Menjadikan TextField sebagai input multiline
                      rows={2} // Menentukan jumlah baris yang ditampilkan
                    />
                  </Grid>
                  {/* Grid di sebelah kanan */}
                  <Grid xs={6}>
                    <TextField
                      label="Foto KTP"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            component="label"
                            size="small"
                            sx={{
                              width: "fit-content",
                              marginRight: "-13px",
                              color: "#f8f8ff",
                            }}
                          >
                            Choose File
                            <input type="file" style={{ display: "none" }} />
                          </Button>
                        ),
                      }}
                    />

                    <TextField
                      label="City Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField
                      label="Subarea Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField label="Postal Code" fullWidth />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <MDButton
              color="light"
              onClick={handleSubmit}
              sx={{ marginTop: 1, color: "light", border: "1px solid grey", width: "120px" }}
            >
              Save
            </MDButton>

            <MDButton
              color="light"
              onClick={closePersonalPopup}
              sx={{
                marginTop: 1,
                marginLeft: 1,
                color: "light",
                border: "1px solid grey",
                width: "120px",
              }}
            >
              Exit
            </MDButton>
          </div>
        </ModalCanvas>
      )}

      {/* Popup untuk Companies */}
      {showCompaniesPopup && (
        <ModalCanvas
          open={true}
          onClose={closeCompaniesPopup}
          title={action + "Master Customer (New) - Companies"}
          maxWidth="xl"
          sx={{ textAlign: "left" }}
        >
          {/* disini */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: -1,
            }}
          ></Box>
          <Grid container spacing={3}>
            {/* Grid di sebelah kiri */}
            <Grid xs={6}>
              <TextField label="Customer Code" fullWidth sx={{ marginBottom: 2 }} />
              <TextField label="Place of Birth" fullWidth sx={{ marginBottom: 2 }} />
              <TextField
                label="Gender"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon fontSize="medium" />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Marital Status"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon fontSize="medium"></KeyboardArrowDownSharpIcon>
                    </IconButton>
                  ),
                }}
              />

              {/* Grid untuk nomor telepon */}
              <Grid container spacing={1}>
                <Grid fullWidth xs={9}>
                  <TextField label="Phone" fullWidth sx={{ marginBottom: 2, marginRight: 17 }} />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    label="+62 - Indonesia"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <KeyboardArrowDownSharpIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {/* Grid untuk WhatsApp */}

              <Grid container spacing={1}>
                <Grid fullWidth xs={9}>
                  <TextField label="WhatsApp" fullWidth sx={{ marginBottom: 2, marginRight: 17 }} />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    label="+62 - Indonesia"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <KeyboardArrowDownSharpIcon></KeyboardArrowDownSharpIcon>
                        </IconButton>
                      ),
                    }}
                  />
                  <Grid xs={3}></Grid>
                </Grid>
                <Grid xs={3}></Grid>
              </Grid>
              {/* Grid untuk email */}
              <TextField label="Email" fullWidth />
            </Grid>

            {/* Grid di sebelah kanan */}
            <Grid xs={6}>
              <TextField label="Customer Name" fullWidth sx={{ marginBottom: 2 }} />
              <Grid container spacing={1}>
                <Grid xs={6} sx={{ marginBottom: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Date of Birth" fullWidth />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <TextField
                label="Religion"
                fullWidth
                sx={{ marginBottom: 2, marginTop: 1 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Occupations"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Citizen Status"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Range Salary"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Info From"
                fullWidth
                sx={{ marginTop: 0.5 }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <KeyboardArrowDownSharpIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {/* Tabel kedua ............................ */}

          {/* Grid data */}
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Paper
                elevation={3}
                variant="outlined"
                sx={{ padding: 1, borderColor: "black", borderWidth: "12px" }}
              >
                {/* Penambahan tombol-tombol di sini */}
                <Grid container justifyContent="flex-start" spacing={2} sx={{ marginTop: -3 }}>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      KTP
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      NPWP
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      Correspondence
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="contained" size="small" color="inherit" sx={{ color: "grey" }}>
                      Domisili
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  {/* Grid di sebelah kiri */}
                  <Grid xs={6}>
                    <TextField label="ID" fullWidth sx={{ marginBottom: 2 }} />
                    <TextField
                      label="State Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField
                      label="Area Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField
                      label="Address"
                      fullWidth
                      multiline // Menjadikan TextField sebagai input multiline
                      rows={2} // Menentukan jumlah baris yang ditampilkan
                    />
                  </Grid>
                  {/* Grid di sebelah kanan */}
                  <Grid xs={6}>
                    <TextField
                      label="Foto KTP"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            component="label"
                            size="small"
                            sx={{
                              width: "fit-content",
                              marginRight: "-13px",
                              color: "#f8f8ff",
                            }}
                          >
                            Choose File
                            <input type="file" style={{ display: "none" }} />
                          </Button>
                        ),
                      }}
                    />

                    <TextField
                      label="City Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField
                      label="Subarea Name"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <KeyboardArrowDownSharpIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <TextField label="Postal Code" fullWidth />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <MDButton
              color="light"
              onClick={handleSubmit}
              sx={{ marginTop: 1, color: "light", border: "1px solid grey", width: "120px" }}
            >
              Save
            </MDButton>

            <MDButton
              color="light"
              onClick={closeCompaniesPopup}
              sx={{
                marginTop: 1,
                marginLeft: 1,
                color: "light",
                border: "1px solid grey",
                width: "120px",
              }}
            >
              Exit
            </MDButton>
          </div>
        </ModalCanvas>
      )}

      <MDButton
        color="dark"
        onClick={onClose}
        sx={{ position: "absolute", bottom: "10px", right: "20px" }}
      >
        Cancel
      </MDButton>
    </ModalCanvas>
  );
};
