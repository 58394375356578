import { Route, Routes } from "react-router-dom";
import { router } from "./router"

const getRoute = (route = []) => {
    return route.flatMap(({ collapse, route, component }) => {
        if (collapse) {
            return getRoute(route);
        } else {
            return <Route exact path={route} element={component} key={route} />;
        }
    });
}

export const ListRoute = () => {
    const route = getRoute(router);
    return (<Routes>
        {route}
    </Routes>);
}