import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import { setDataMap, setMiniSidenav, useLayoutUIController } from "../../context";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// @mui material components
import { Divider, Icon, List } from "@mui/material";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";
import SidenavItem from "./SidenavItem";
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";

import { mapDefault, menuSidenav } from "../../../routes/router";
import { findMap } from "../../../helper/function";

function Sidenav({ color, brandName, ...rest }) {
    const [controller, dispatch] = useLayoutUIController();
    const { miniSidenav, darkMode, dataMap } = controller;
    const transparentSidenav = false;
    const whiteSidenav = false;
    const location = useLocation();

    let textColor = "white";
    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = "dark";
    } else if (whiteSidenav && darkMode) {
        textColor = "inherit";
    }

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    const [openCollapse, setOpenCollapse] = useState([]);
    const activeMenu = findMap(dataMap, location.pathname, "active");

    // Load Awal
    useEffect(() => {
        const setRouteMap = (dt = [], parentKey = [], breadcrumbs = []) => {
            return dt.flatMap(({ child, menucode, menuname, menuurl }) => {
                let data;
                if (child) {
                    data = setRouteMap(child, [...parentKey, menucode], [...breadcrumbs, menuname]);
                } else {
                    data = {
                        url: menuurl,
                        name: menuname,
                        menuActive: [...parentKey, menucode],
                        breadcrumbs: [...breadcrumbs, menuname]
                    };
                }
                return data;
            });
        };
        // Run Fungtion Get Menu
        //
        const mapData = [...mapDefault, ...setRouteMap(menuSidenav)];
        setDataMap(dispatch, mapData);
        setOpenCollapse(findMap(mapData, location.pathname, "active"));
    }, []);

    // Resize Windows
    useEffect(() => {
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }
        window.addEventListener("resize", handleMiniSidenav);
        if (miniSidenav !== (window.innerWidth < 1200)) {
            handleMiniSidenav();
        }
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [location]);

    // Render Menu
    const renderMenu = (allMenu = [], index = 0, parentKey = []) => {
        const ElementNav = index === 0 ? SidenavCollapse : SidenavItem;
        return allMenu.map(({ menucode, menuname, icon, menuurl, child }) => {
            let respon;
            if (child) {
                respon = (
                    <ElementNav key={menucode} name={menuname} icon={icon} color={color}
                        active={menucode === activeMenu[index]}
                        open={menucode === openCollapse[index]}
                        onClick={() => (menucode === openCollapse[index] ? setOpenCollapse([]) : setOpenCollapse([...parentKey, menucode]))}
                    >
                        {renderMenu(child, index + 1, [...parentKey, menucode])}
                    </ElementNav>
                );
            } else {
                respon = (
                    <NavLink to={menuurl} key={menucode}>
                        <ElementNav key={menucode} name={menuname} icon={icon} color={color}
                            active={menucode === activeMenu[index]}
                        // onClick={(e) => (!e.ctrlKey) ? setActiveMenu([...parentKey, menucode]) : undefined}
                        />
                    </NavLink>
                );
            }
            return (index === 0) ? respon : (<SidenavList key={menucode}>{respon}</SidenavList>);
        });
    }

    return (<SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
        <MDBox pt={3} pb={1} px={4} textAlign="center">
            <MDBox
                display={{ xs: "block", xl: "none" }}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={closeSidenav}
                sx={{ cursor: "pointer" }}
            >
                <MDTypography variant="h6" color="secondary">
                    <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                </MDTypography>
            </MDBox>
            <MDBox component={NavLink} to="/" display="flex" alignItems="center">
                {/* Logo Program eProperty */}
                <MDBox
                    width={!brandName && "100%"}
                    sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                >
                    <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                        {brandName}
                    </MDTypography>
                </MDBox>
            </MDBox>
        </MDBox>
        <Divider
            light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
            }
        />
        <List>
            <SidenavCollapse
                name={"Riza Purwandi"}
                icon={"person2"}
                active={"cp_profile" === activeMenu[0]}
                open={openCollapse[0] === "cp_profile"}
                onClick={() => (openCollapse[0] === "cp_profile" ? setOpenCollapse([]) : setOpenCollapse([...[], "cp_profile"]))}
            >
                <SidenavList>
                    <NavLink to={"/Profile"} sx={{ textDecoration: "none" }}>
                        <SidenavItem color={color} name={"Profile"} active={"profile" === activeMenu[1]} />
                    </NavLink>
                    <NavLink to={"/ChangePassword"} sx={{ textDecoration: "none" }}>
                        <SidenavItem color={color} name={"Change Password"} active={"change_password" === activeMenu[1]} />
                    </NavLink>
                    <NavLink to={"/Logout"} sx={{ textDecoration: "none" }}>
                        <SidenavItem color={color} name={"Log out"} active={"logout" === activeMenu[1]} />
                    </NavLink>
                </SidenavList>
            </SidenavCollapse>
            <Divider
                light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                }
            />
            {renderMenu(menuSidenav)}
        </List>
    </SidenavRoot>);
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brandName: PropTypes.string.isRequired,
};
export default Sidenav;