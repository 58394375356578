import PropTypes from "prop-types";
import { Backdrop, Card, CircularProgress, Divider, Icon } from "@mui/material";

import MDBox from "../MDBox";
import MDButton from "../MDButton";
import MDTypography from "../MDTypography";
import MDSnackbar from "../MDSnackbar";
import { alertMessage } from "../../helper/constant";

export const PageCanvas = ({ children, title, ...rest }) => {
    return (
        <MDBox pt={6} pb={3}>
            <MDBox mb={3}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                            {title}
                        </MDTypography>
                    </MDBox>
                    {title && (
                        <Divider variant="middle" sx={{ opacity: 1, margin: "0 0 1rem" }} />
                    )}
                    {children}
                </Card>
            </MDBox>
        </MDBox>
    );
}
PageCanvas.defaultProps = { title: "" };
PageCanvas.propTypes = { title: PropTypes.string };

export const DataTableBtn = ({ color, icon, children, ...rest }) => {
    return (
        <MDButton color={color} size={"small"} variant={"outlined"} {...rest}>
            {icon && (<Icon>{icon}</Icon>)} {children}
        </MDButton>
    );
}
DataTableBtn.defaultProps = { color: "info", icon: "" };
DataTableBtn.propTypes = {
    color: PropTypes.oneOf(["white", "primary", "secondary", "info", "success", "warning", "error", "light", "dark"]),
    icon: PropTypes.string
};

export const MessageNotice = ({ duration, position, open, color, title, content }) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        alertMessage.setGlobalState("open", false);
    };
    return (<MDSnackbar
        icon={"notifications"}
        autoHideDuration={duration}
        anchorOrigin={position}
        color={color}
        dateTime=""
        title={title}
        content={content}
        open={open}
        onClose={handleClose}
        close={handleClose}
    />);
}
MessageNotice.defaultProps = {
    duration: 2000,
    position: { vertical: "top", horizontal: "right" },
    color: "info",
    open: false,
    title: "",
    content: "",
};
MessageNotice.propTypes = {
    duration: PropTypes.number,
    position: PropTypes.shape({
        vertical: PropTypes.oneOf(["top", "center", "bottom"]),
        horizontal: PropTypes.oneOf(["left", "center", "right"])
    }),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    content: PropTypes.string,

};

export const LoadingScreen = ({ open }) => {
    return (
        <Backdrop sx={{
            color: '#fff', zIndex: (theme) => {
                // theme.zIndex.drawer + 1
                return 9999;
            }
        }} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
LoadingScreen.defaultProps = { open: false };
LoadingScreen.propTypes = { open: PropTypes.bool.isRequired };