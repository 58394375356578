import * as React from "react";
import {
  Avatar,
  Button,
  styled,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Stack,
  colors,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { PageLayout } from "../../helper/PageFunction";
import FormGroup from "../../components/BaseElement/FormData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import { useNavigate, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import IconButton from "@mui/icons-material/IconButton";

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://digitalhasanah.co.id/">
        Digital Hasanah Indonesia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const defaultTheme = createTheme();

const ForgotPassword = () => {
  PageLayout();
  const onSubmit = (data) => {
    console.log(data);
    navigate("/Login");
  }
  const { handleSubmit, control } = useForm({
    defaultValues: { email: "", newPassword: "", confirmPassword: "" },
    resolver: yupResolver(
      yup.object({
        email: yup.string().email().required(),
        newPassword: yup.string().required().min(8),
        confirmPassword: yup.string().required().min(8).oneOf([yup.ref("newPassword")]),
      })
    ),
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handlePasswordVisibility1 = () => {
    setShowPassword1((prev) => !prev);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#060d0d"),
    backgroundColor: "#060d0d",
    "&:hover": {
      backgroundColor: "#122828",
    },
  }));

  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://i.pinimg.com/originals/c3/01/c6/c301c6c05c5903f370ebbafb02919e6f.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#060d0d" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                placeholder="Email Address"
                name="email"
                label="Email Address"
                autoComplete="email"
                InputProps={{
                  startAdornment: (
                    <EmailIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                  ),
                }}
              />
                <FormGroup
                  control={control}
                  margin="normal"
                  required
                  fullWidth
                  placeholder="New Password"
                  label="New Password (8-16 characters)"
                  name="newPassword"
                  inputProps={{ maxlength: 16 }}
                  type={showPassword ? "text" : "password"}
             InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
                />
                <FormGroup
                  control={control}
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  inputProps={{ maxlength: 16 }}
                  type={showPassword1 ? "text" : "password"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility1} edge="end">
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
                />
              </Box>
              <ColorButton
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </ColorButton>
              <Routes>
                 <Route path="/Login" element={<login />} />
              </Routes>
              <Copyright sx={{ mt: 5 }} />
            </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ForgotPassword;
